import SuccessBanner from "../components/success/SuccessBanner";
import SuccessIntro from "../components/success/SuccessIntro";
import SuccessGoals from "../components/success/SuccessGoals";
import SuccessCreators from "../components/success/SuccessCreators";
import SuccessLogotypes from "../components/success/SuccessLogotypes";
import SuccessEventReports from "../components/success/SuccessEventReports";

const Success = () => {
    return (
        <div className='success'>
            <SuccessBanner/>
            <div className='success_container'>
                <SuccessIntro/>
                <SuccessGoals/>
                <SuccessCreators/>
                <SuccessLogotypes/>
                <SuccessEventReports/>
            </div>
        </div>
    );
}

export default Success;