const SuccessGoals = () => {
    const goals = [
        {
            title: 'rozwijanie umiejętności abstrakcyjnego i logicznego rozumowania,',
            description: 'interpretowania informacji i dostrzegania prawidłowości matematycznych i fizycznych w otaczającym świecie.',
            backgroundColor: "#EDF4F6",
        },
        {
            title: 'kształtowanie pozytywnego nastawienia do podejmowania wysiłku intelektualnego',
            description: 'oraz postawy dociekliwości, wyrobienie nawyku obserwacji i eksperymentowania.',
            backgroundColor: "#DBE9EC",
        },
        {
            title: 'realizacja warsztatów dla uczniów szkół podstawowych oraz ponadpodstawowych w szkołach',
            description: 'a w przyszłości stworzenie przestrzeni, w której praca z uczniem zdolnym w kierunku przedmiotów ścisłych, będzie prowadzona w <b>systematyczny, profesjonalny sposób przez najlepszych mentorów</b>.',
            backgroundColor: "#B7D4DA",
        }
    ];

    return (
        <div className="success_goals">
            <h3>Nasz cel</h3>
            <div className='success_goals-container'>
                {goals.map((goal, index) => (
                    <div className="success_goal" key={index} style={{backgroundColor: goal.backgroundColor}}>
                        <div className="success_goal-text">
                            <h3>{goal.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: goal.description}}/>
                        </div>
                        <span>{String(index + 1).padStart(2, '0')}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SuccessGoals;
