import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.png';

import Footer from '../components/Footer';
import MainContent from "../components/MainContent";

const Main = () => {
	return (
		<>
			<Link to='/'>
				<img className='main-logo' src={Logo} alt='Logo' />
			</Link>
			<MainContent />
			<Footer />
		</>
	);
};

export default Main;
