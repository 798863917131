import React from 'react';
import TrainingBannerImg from "../../assets/img/training/training_banner.png";

const TrainingBanner = () => {
    return (
        <div className='training_banner'>
            <img src={TrainingBannerImg} alt='Baner z Natalią Zarańską oraz Ignacym Rejmakiem'/>
        </div>
    );
}
export default TrainingBanner;