import TrainingModuleCard from './TrainingModuleCard';
import TrainingModule from './TrainingModule';

const TrainingModules = () => {
	const modulesCards = [
		{
			title: 'Innowacyjna Edukacja:',
			description: 'Wykorzystaj potencjał uczniów zdolnych!',
			backgroundColor: '#EBF3E6',
		},
		{
			title: 'Transformacja Edukacji:',
			description:
				'Rozwój kluczowych kompetencji nauczycieli oraz skuteczny dialog z rodzicami uczniów zdolnych.',
			backgroundColor: '#D7E6CD',
		},
		{
			title: 'Nowoczesna Edukacja:',
			description: 'Tworzenie wspierającego środowiska dla uczniów zdolnych.',
			backgroundColor: '#C2DAB3',
		},
	];

	const modules = [
		{
			title: 'Innowacyjna Edukacja: Wykorzystaj potencjał uczniów zdolnych!',
			description:
				'Szkolenie w Module I pozwoli uczestnikom spojrzeć na edukację uczniów zdolnych w zupełnie nowy sposób. Zastanowimy się nad profilem ucznia zdolnego, a także odpowiemy sobie na pytanie jaki procent w każdej szkole, mogą stanowić uczniowie osiągający tytuł laureata i finalisty konkursów przedmiotowych oraz jak tego dokonać. Przeprowadzimy ciekawe eksperymenty ukazujące znaczenie indywidualizacji nauczania, nieszablonowe metody pracy. Wskażemy jaką rolę w pracy z uczniem zdolnym odgrywają specjaliści: psycholog i pedagog szkolny.</br></br>Podczas szkolenia ukażemy także rolę dyrektora szkoły, a także porozmawiamy na temat współpracy z rodzicami. To szkolenie to doskonała okazja do wymiany doświadczeń, praktycznych warsztatów i zdobycia wiedzy, która pozwoli nauczycielom efektywniej pracować z uczniami szczególnie uzdolnionymi, rozwijać ich talenty oraz przełamywać schematy tradycyjnego nauczania.<br/><br/>Dołącz do nas i naucz się, jak zidentyfikować i wspierać uczniów zdolnych, by mogli osiągnąć pełnię swojego potencjału. Razem przełamiemy bariery i zastanowimy się jak stworzyć przestrzeń, gdzie każda wybitna jednostka ma szansę zabłysnąć!',
			quote:
				'<b>Zdolne osoby czują brak zainteresowania ze strony dorosłych.</b><br/>dobre oceny, właściwe zachowanie<br/>=<br/>brak problemów, nie potrzebuje pomocy, wsparcia*',
		},
		{
			title:
				'Transformacja Edukacji: Rozwój kluczowych kompetencji nauczycieli oraz skuteczny dialog z rodzicami uczniów zdolnych.',
			description:
				'Zapraszamy na wyjątkowe szkolenie, które pomoże odkryć nauczycielom nowe podejścia do nauczania, wzmocnić pewność siebie i zbudować silniejsze relacje z rodzicami uczniów. To intensywne spotkanie nie tylko dostarczy narzędzi niezbędnych do pracy, ale także zainspiruje do wdrażania kreatywnych metod w codziennej praktyce.<br/><br/>Podczas szkolenia nauczyciele poznają techniki budowania pewności siebie, aby odkryć własne lęki, a także nauczyć się jak je pokonywać oraz jak wzmocnić swoją motywację wewnętrzną. Zainspirujemy nauczycieli nowatorskimi metodami nauczania, które przełamują tradycyjne schematy i angażują uczniów. <br/><br/>Szkolenie ukaże także nauczycielom możliwości do tworzenia skutecznego partnerstwa z rodzicami, aby wspólnie wspierać rozwój uczniów. Podczas tej części porozmawiamy o różnicy pomiędzy wartością własną, a samooceną dziecka, gdyż zdolni uczniowie zbyt często mają w tym obszarze trudności. Wskażemy zatem w jaki sposób rozmawiać z rodzicami, aby wzmacniać poczucie własnej wartości uczniów, rozwijać sprawczość oraz przeciwdziałać rozwijającemu się u uczniów zdolnych perfekcjonizmowi. <br/><br/> Moduł II to skupienie uwagi na dwóch najważniejszych grupach wspierających ucznia zdolnego: nauczycielach i rodzicach. Dlatego też podczas szkolenia, dostarczymy nauczycielom wiedzy i umiejętności, aby stworzyli przestrzenie sprzyjające młodemu człowiekowi, przestrzenie wzmacniające jego wartość własną, akceptujące i co najważniejsze pozwalające na błędy, porażki i odpoczynek.',
			quote:
				'Zdolni uczniowie często dźwigają na swoich barkach dużo więcej niż powinni. Przecież są tylko dziećmi i powinni dojrzewać ze świadomością, że każdy człowiek może mieć lepsze i gorsze momenty i te gorsze okresy nie czynią go mniej wartościową osobą.*',
		},
		{
			title:
				'Nowoczesna Edukacja: Tworzenie wspierającego środowiska dla uczniów zdolnych.',
			description:
				'Moduł III  to idealna okazja, by zrewolucjonizować podejście do nauczania i stworzyć przestrzeń, w której każdy uczeń zdolny może się rozwijać i osiągać sukcesy. Podczas szkolenia podsumujemy całą zdobytą wiedzę z Modułu I i II, aby wspólnie stworzyć w szkole przestrzeń wspierającą ucznia zdolnego. Wspólnie z nauczycielami stworzymy plan działań dla szkoły na najbliższy czas. Porozmawiamy na temat obaw i pokażemy nauczycielom, że w edukacji można działań z odwagą pomimo lęku i łamać szkolne schematy. Jeszcze raz zastanowimy się nad rozwijaniem motywacji wewnętrznej u nauczycieli i uczniów, aby stworzony plan działań był autorski, oparty na potrzebach i specyfikacji szkoły, a także, aby jego fundamentem były mocne strony nauczycieli, ich wartości i talenty. Wykorzystamy do tego metodę pracy jaką jest job crafting czyli najskuteczniejszą metodę budowania zaangażowania i sensu pracy. Szkolenie odpowie na trzy niezwykle istotne pytania, stawiane przez nauczycieli w pracy z uczniem zdolnym: <ul><li>Jak budować pozytywne poczucie własnej wartości u uczniów zdolnych, aby nie wpadli oni w pułapkę perfekcjonizmu?</li><li>Jak radzić sobie z presją wyników i tworzyć zdrowe, wspierające środowisko edukacyjne, aby nie wpaść w pułapkę tabelek i wyników?</li><li>Jak wspólnie pracować nad zmianami, które promują rozwój uczniów zdolnych, aby nie odczuwali presji oczekiwań ze strony nauczycieli i rodziców?</li></ul>',
			quote:
				'Osoby bez odpowiedniego wsparcia, mogą czuć się ciągle niewystarczające i dążyć do nieosiągalnych przez nich celów. Mogą zacząć być perfekcjonistami, a według mnie to pułapka, ktoś zamyka kreatywność i wolność człowieka.*',
		},
	];

	return (
		<div className='training_modules'>
			<h3>Moduły</h3>
			<div className='training_modules-cards-container'>
				{modulesCards.map((moduleCard, index) => (
					<TrainingModuleCard index={index} moduleCard={moduleCard}/>
				))}
			</div>
			<div className='training_modules-container'>
				{modules.map((module, index) => (
					<TrainingModule index={index} module={module}/>
				))}
			</div>
			<div className="training_divider"></div>
		</div>
	);
};

export default TrainingModules;
