import SuccessBannerImg from '../../assets/img/success/success_banner.png'

const SuccessBanner = () => {
    return (
        <div className='success_banner'>
            <img src={SuccessBannerImg} alt='Baner z logiem Sucesu nie mierzy się oceną'/>
        </div>
    );
};

export default SuccessBanner;