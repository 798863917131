import TrainingLeaderNatalia1 from '../../assets/img/training/leaders/leader_natalia_1.png'
import TrainingLeaderNatalia2 from '../../assets/img/training/leaders/leader_natalia_2.png'
import TrainingLeaderIgnacy1 from '../../assets/img/training/leaders/leader_ignacy_1.png'
import TrainingLeaderIgnacy2 from '../../assets/img/training/leaders/leader_ignacy_2.png'
import TrainingLeaderIgnacy3 from '../../assets/img/training/leaders/leader_ignacy_3.png'
import ButtonWithArrow from "../common/ButtonWithArrow";

const TrainingLeaders = () => {
    return (
        <>
            <div className='training_leaders'>
                <section>
                    <div className='training_leader'>
                        <section>
                            <div className='training_leader-text'>
                                <span>Prowadzący szkolenie</span>
                                <h3>Natalia Zarańska</h3>
                                <p>
                                    Coach, trener i mówca Maxwell Leadership Certified Team Poland, trener psychologii
                                    pozytywnej,
                                    facylitator job craftingu, doradca zawodowy. Zakochana w zmianie. Z pierwszego
                                    wykształcenia
                                    jest pedagogiem i przez 22 lata prowadziła młodych ludzi w okresie dojrzewania,
                                    wspierała
                                    także
                                    rodziców, prowadząc szkolenia i warsztaty.
                                    <br/><br/>
                                    Właścicielka Akademii Rozwoju Prosperity oraz Soward Smart Kids Academy będącej
                                    przestrzenią
                                    rozwoju młodych ludzi w zakresie kompetencji społecznych, matematyki mentalnej,
                                    edukacji
                                    medialnej i krytycznego korzystania z mediów oraz międzynarodowej metody Brainy
                                    Sensory
                                    Enhancement.
                                </p>
                            </div>
                            <img src={TrainingLeaderNatalia1} className='image-big' alt="Natalia Zarańska"/>
                        </section>
                        <section>
                            <img src={TrainingLeaderNatalia2} className='image-medium' alt="Natalia Zarańska"/>
                            <div className='training_leader-text'>
                                <p>
                                    Od wielu lat wspiera nauczycieli prowadząc szkoleniowe Rady Pedagogiczne,
                                    współpracując
                                    w tym zakresie m.in. z Pomorskim Ośrodkiem Doskonalenia Nauczycieli w Słupsku.
                                    Prezes
                                    Stowarzyszenia FPUNKT. Finalistka konkursu im. Ireny Sendlerowej „Za naprawianie
                                    świata”. Absolwentka programu wymiany rządu USA International Visitor Leadership. Za
                                    swoje działania otrzymała Medal Komisji Edukacji Narodowej.
                                    <br/><br/>
                                    Jej pasją są musicale, które tworzy wspólnie z młodzieżą. Jej spektakle stanęły na
                                    Stadionie Narodowym (spektakl „Szukając siebie”) a także na dużej scenie Teatru
                                    Muzycznego w Gdyni (musical „Szepty serc”). Przez wiele lat realizowała profilaktykę
                                    rówieśniczą pokazując młodym ludziom możliwości własnego rozwoju, ucząc, że
                                    ciekawość to
                                    nie pierwszy stopień do piekła, a możliwość dokonywania niemożliwego.
                                </p>
                                <div className='training_leader-links'>
                                    <ButtonWithArrow link='//nataliazaranska.pl' backgroundColor='#9AC181'
                                                     text='strona internetowa' isInternalLink={false}/>
                                    <ButtonWithArrow link='//smartkids.soward.eu' backgroundColor='#9AC181'
                                                     text='Soward Smart Kids' isInternalLink={false}/>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <div className="training_divider"></div>
                <section>
                    <div className='training_leader'>
                        <section>
                            <div className='training_leader-text'>
                                <span>Prowadzący szkolenie</span>
                                <h3>Ignacy Rejmak</h3>
                                <p>
                                    Nauczyciel fizyki w Szkole podstawowej nr 1 w Bolszewie, Podstawowej Ekologicznej
                                    Szkole
                                    Społecznej w Rumi i X LO w Gdyni.
                                    <br/><br/>
                                    Pracuje z uczniami szczególnie uzdolnionymi w kierunku przedmiotów ścisłych,
                                    przygotowując ich do konkursów i olimpiad zarówno fizycznych, matematycznych i
                                    informatycznych. Przez ten czas wychował ponad 30 laureatów i finalistów, głównie
                                    konkursów fizycznych. Od 15 lat współorganizuje obozy naukowe.
                                    <br/><br/>
                                    Przez swoje niekonwencjonalne metody nauczania, niezwykłą osobowość a także relacje
                                    z
                                    młodzieżą, często porównywany do bohatera książki „Stowarzyszenie Umarłych Poetów”.
                                    Twierdzi, że ma więcej szczęścia niż rozumu, gdyż w szkołach w których pracuje
                                    zawsze
                                    trafia na nauczycieli, od których ciągle może się bardzo wiele nauczyć.
                                </p>
                            </div>
                            <img src={TrainingLeaderIgnacy1} className='image-big' alt="Ignacy Rejmak"/>
                        </section>
                        <section>
                            <img src={TrainingLeaderIgnacy2} className='image-medium' alt="Ignacy Rejmak"/>
                            <div className='training_leader-text'>
                                <p>
                                    Ignacy Rejmak jest bohaterem niezwykłego filmu dokumentalnego, który swoją premierę
                                    miał
                                    we wrześniu 2020. “Nieletni inżynierowie” to historia przemiany, odkrywania pasji i
                                    zdobywania wiedzy. To opowieść o miłości do nauki i mocy relacji, tak bardzo nam
                                    dzisiaj
                                    potrzebnej.
                                    <br/><br/>
                                    Film pojawił się na międzynarodowych festiwalach otrzymując wiele cennych nagród
                                    takich
                                    jak: Festiwal Cineminha B. w Brazylii, International CHINH INDIA KIDS FILM FESTIVAL
                                    w
                                    Indiach, Delco Film Festival w USA, Polish Film Festival Los Angeles, Festiwalu
                                    Filmowym
                                    Zlin w Czechach, Satisfied Eye International Film Festival w Wielkiej Brytanii gdzie
                                    stał się Najlepszym Filmem Dokumentalnym, a Ignacy Rejmak otrzymał na tym festiwalu
                                    Nagrodę Jury Satisfied Eye International Film Festival.
                                </p>
                                <div className='training_leader-links'>
                                    <ButtonWithArrow link='//underage.pro/inzynierowie' backgroundColor='#9AC181'
                                                     text='więcej o filmie' isInternalLink={false}/>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='training_leader-text'>
                                <p>
                                    Ponadto Ignacy Rejmak wraz z Przemysławem Rojewskim uczestniczy w unikalnym
                                    projekcie
                                    edukacyjnomedialnym dla uczniów i nauczycieli klas szkół podstawowych oraz średnich
                                    realizowanym w Powiatowym Zespole Szkół nr 2 im. Bohaterskiej Załogi ORP “Orzeł” w
                                    Wejherowie „Fizyka dla nieletnich”.
                                    <br/><br/>
                                    Projekt zakłada tworzenie 12-minutowych odcinków dla uczniów, które są dostępne na
                                    Facebooku oraz kanale YouTube. Ich celem jest wyjaśnianie zjawisk fizycznych w
                                    prosty,
                                    przystępny, a zarazem ciekawy sposób, a w efekcie zachęcenie młodych ludzi do
                                    zawodów i
                                    kierunków technicznych
                                </p>
                                <div className='training_leader-links'>
                                    <ButtonWithArrow link='//underage.pro/fizyka' backgroundColor='#9AC181'
                                                     text='więcej o projekcie' isInternalLink={false}/>
                                </div>
                            </div>
                            <img src={TrainingLeaderIgnacy3} className='image-medium' alt="Ignacy Rejmak"/>
                        </section>
                    </div>
                </section>
            </div>
            <div className="training_divider"></div>
        </>
    );
};

export default TrainingLeaders;