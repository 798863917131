import SuccessLogotypesImg from '../../assets/img/success/success_logotypes.png'

const SuccessLogotypes = () => {
    return (
        <div className='success_logotypes'>
            <img src={SuccessLogotypesImg} alt='Sponsorzy strategiczni, organizatorzy, patronat, partnerzy'/>
        </div>
    );
};

export default SuccessLogotypes;