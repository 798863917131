import React from 'react';
import TrainingBanner from "../components/teacherTraining/TrainingBanner";
import TrainingIntro from "../components/teacherTraining/TrainingIntro";
import TrainingModules from "../components/teacherTraining/TrainingModules";
import TrainingMotivation from "../components/teacherTraining/TrainingMotivation";
import TrainingLeaders from "../components/teacherTraining/TrainingLeaders";
import TrainingPricing from "../components/teacherTraining/TrainingPricing";

const TeacherTraining = () => {
    return (
        <div className='training'>
            <TrainingBanner/>
            <div className='training_container'>
                <TrainingIntro/>
                <TrainingModules/>
                <TrainingMotivation/>
                <TrainingLeaders/>
                <TrainingPricing/>
            </div>
        </div>
    );
};

export default TeacherTraining;