import React from 'react';
import { toRoman } from '../../functions/functions';

const TrainingModuleCard = ({ moduleCard, index }) => {
	return (
		<div
			className='training_module-card'
			key={index}
			style={{ backgroundColor: moduleCard.backgroundColor }}
		>
			<div className='training_module-card-text'>
				<p>{moduleCard.title}</p>
				<h3>{moduleCard.description}</h3>
			</div>
			<span>{toRoman(index + 1)}</span>
		</div>
	);
};

export default TrainingModuleCard;
