import React from 'react';
import SuccessEventReport from "./SuccessEventReport";

const SuccessEventReports = () => {
    const reports = [{title: '', description: '', date: '', photos: []}]
    return (
        <div style={{display: "none"}}>
            <h3>Relacje z wydarzeń</h3>
            {reports.map(report => <SuccessEventReport report={report}/>)}
        </div>
    );
};

export default SuccessEventReports;