import IRimg1 from '../assets/img/ir1.jpg';
import IRimg2 from '../assets/img/ir2.jpg';
import IRimg3 from '../assets/img/ir3.jpg';

import NZimg1 from '../assets/img/nz1.jpg';
import NZimg2 from '../assets/img/nz2.jpg';
import NZimg3 from '../assets/img/nz3.jpg';

import BPimg1 from '../assets/img/bp1.jpg';
import BPimg2 from '../assets/img/bp2.jpg';
import BPimg3 from '../assets/img/bp3.jpg';

import ASimg1 from '../assets/img/as1.jpg';
import ASimg2 from '../assets/img/as2.jpg';
import ASimg3 from '../assets/img/as3.jpg';

import WMimg1 from '../assets/img/wm1.jpg';
import WMimg2 from '../assets/img/wm2.jpg';
import WMimg3 from '../assets/img/wm3.jpg';

import PRimg1 from '../assets/img/pr1.jpg';
import PRimg2 from '../assets/img/pr2.jpg';
import PRimg3 from '../assets/img/pr3.jpg';

import KKimg1 from '../assets/img/kk1.jpg';
import KKimg2 from '../assets/img/kk2.jpg';
import KKimg3 from '../assets/img/kk3.jpg';

import JRimg1 from '../assets/img/jr1.jpg';
import JRimg2 from '../assets/img/jr2.jpg';
import JRimg3 from '../assets/img/jr3.jpg';

import MWimg1 from '../assets/img/mw1.jpg';
import MWimg2 from '../assets/img/mw2.jpg';
import MWimg3 from '../assets/img/mw3.jpg';

import ETimg1 from '../assets/img/et1.jpg';
import ETimg2 from '../assets/img/et2.jpg';
import ETimg3 from '../assets/img/et3.jpg';

import MKimg1 from '../assets/img/mk1.jpg';
import MKimg2 from '../assets/img/mk2.jpg';
import MKimg3 from '../assets/img/mk3.jpg';

import Team1 from '../assets/img/colonyTeam/team1.jpg';
import Team2 from '../assets/img/colonyTeam/team2.jpg';
import Team3 from '../assets/img/colonyTeam/team3.jpg';

const TeamPerson = () => {
    const teamPersons = [
        {
            name: 'Ignacy Rejmak',
            id: 'Ignacy-Rejmak',
            img1: IRimg1,
            img2: IRimg2,
            img3: IRimg3,
            text: [
                '- nauczyciel fizyki w Szkole podstawowej nr 1 w Bolszewie, Podstawowej Ekologicznej Szkole Społecznej w Rumi i X LO w Gdyni.',
                <br/>,
                <br/>,
                'Pracuje z uczniami szczególnie uzdolnionymi w kierunku przedmiotów ścisłych, przygotowując ich do konkursów i olimpiad zarówno fizycznych, matematycznych i informatycznych. Przez ten czas wychował ponad 30 laureatów i finalistów, głównie konkursów fizycznych. Od 15 lat współorganizuje obozy naukowe.',
                <br/>,
                <br/>,
                'Przez swoje niekonwencjonalne metody nauczania, niezwykłą osobowość a także relacje z młodzieżą, często porównywany do bohatera książki „Stowarzyszenie Umarłych Poetów”. Twierdzi, że ma więcej szczęścia niż rozumu, gdyż w szkołach w których pracuje zawsze trafia na nauczycieli, od których ciągle może się bardzo wiele nauczyć.',
                <br/>,
                <br/>,
                'Ignacy Rejmak jest bohaterem niezwykłego filmu dokumentalnego, który swoją premierę miał we wrześniu 2020. “Nieletni inżynierowie” to historia przemiany, odkrywania pasji i zdobywania wiedzy. To opowieść o miłości do nauki i mocy relacji, tak bardzo nam dzisiaj potrzebnej.',
                <br/>,
                <br/>,
                'Film pojawił się na międzynarodowych festiwalach otrzymując wiele cennych nagród takich jak: Festiwal Cineminha B. w Brazylii, International CHINH INDIA KIDS FILM FESTIVAL w Indiach, Delco Film Festival w USA, Polish Film Festival Los Angeles, Festiwalu Filmowym Zlin w Czechach, Satisfied Eye International Film Festival w Wielkiej Brytanii gdzie stał się Najlepszym Filmem Dokumentalnym, a Ignacy Rejmak otrzymał na tym festiwalu Nagrodę Jury Satisfied Eye International Film Festival.',
                <br/>,
                'Więcej o filmie:',
                <br/>,
                <a href='//underage.pro/inzynierowie '>https://underage.pro/inzynierowie</a>,
                <br/>,
                <br/>,
                'Ponadto Ignacy Rejmak wraz z Przemysławem Rojewskim uczestniczy w unikalnym projekcie edukacyjnomedialnym dla uczniów i nauczycieli klas szkół podstawowych oraz średnich realizowanym w Powiatowym Zespole Szkół nr 2 im. Bohaterskiej Załogi ORP “Orzeł” w Wejherowie „Fizyka dla nieletnich”.',
                <br/>,
                <br/>,
                'Projekt zakłada tworzenie 12-minutowych odcinków dla uczniów, które są dostępne na Facebooku oraz kanale YouTube. Ich celem jest wyjaśnianie zjawisk fizycznych w prosty, przystępny, a zarazem ciekawy sposób, a w efekcie zachęcenie młodych ludzi do zawodów i kierunków technicznych',
                <br/>,
                'Więcej o projekcie:',
                <br/>,
                <a href='//underage.pro/fizyka '>http://underage.pro/fizyka</a>,
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z fizyki.'
            ],
        },
        {
            name: 'Natalia Zarańska',
            id: 'Natalia-Zaranska',
            img1: NZimg1,
            img2: NZimg2,
            img3: NZimg3,
            text: [
                '- coach, trener i mówca Maxwell Leadership Certified Team Poland, trener psychologii pozytywnej, facylitator job craftingu, doradca zawodowy. Zakochana w zmianie. Z pierwszego wykształcenia jest pedagogiem i przez 22 lata prowadziła młodych ludzi w okresie dojrzewania, wspierała także rodziców, prowadząc szkolenia i warsztaty.',
                <br/>,
                <br/>,
                'Właścicielka Akademii Rozwoju Prosperity oraz Soward Smart Kids Academy będącej przestrzenią rozwoju młodych ludzi w zakresie kompetencji społecznych, matematyki mentalnej, edukacji medialnej i krytycznego korzystania z mediów oraz międzynarodowej metody Brainy Sensory Enhancement.',
                <br/>,
                <br/>,
                'Od wielu lat wspiera nauczycieli prowadząc szkoleniowe Rady Pedagogiczne, współpracując w tym zakresie m.in. z Pomorskim Ośrodkiem Doskonalenia Nauczycieli w Słupsku. Prezes Stowarzyszenia FPUNKT. Finalistka konkursu im. Ireny Sendlerowej „Za naprawianie świata”. Absolwentka programu wymiany rządu USA International Visitor Leadership. Za swoje działania otrzymała Medal Komisji Edukacji Narodowej.',
                <br/>,
                <br/>,
                'Jej pasją są musicale, które tworzy wspólnie z młodzieżą. Jej spektakle stanęły na Stadionie Narodowym (spektakl „Szukając siebie”) a także na dużej scenie Teatru Muzycznego w Gdyni (musical „Szepty serc”). Przez wiele lat realizowała profilaktykę rówieśniczą pokazując młodym ludziom możliwości własnego rozwoju, ucząc, że ciekawość to nie pierwszy stopień do piekła, a możliwość dokonywania niemożliwego.',
                <br/>,
                <br/>,
                <a href='//nataliazaranska.pl'>www.nataliazaranska.pl</a>,
                <br/>,
                <a href='//https://smartkids.soward.eu'>
                    https://smartkids.soward.eu
                </a>,
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z kompetencji społecznych.'
            ],
        },
        {
            name: 'Bogusław Pranszke',
            id: 'Boguslaw-Pranszke',
            img1: BPimg1,
            img2: BPimg2,
            img3: BPimg3,
            text: [
                '- dr hab., prof. nadz. Uniwersytetu Morskiego w Gdyni. Pracuje na Wydziale Mechanicznym UMG, w Katedrze Fizyki. Fizyk, specjalność - fizyka doświadczalna. Pracował z uczniami szczególnie uzdolnionymi w kierunku przedmiotów ścisłych, przygotowując ich do olimpiad fizycznych. Zainteresowania: nauczanie oraz  popularyzacja fizyki i astronomii, filmy i książki sf, gry planszowe oraz, od ponad 15 lat, AoC (gra komputerowa Age of Empires II: The Conquerors).',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z fizyki.'
            ],
        },
        {
            name: 'Agata Baranowska',
            id: 'Agata-Baranowska',
            img1: ASimg1,
            img2: ASimg2,
            img3: ASimg3,
            text: [
                '- nauczycielka matematyki w lęborskiej szkole. Absolwentka projektu „Wyobraźnia ważniejsza od wiedzy”, w ramach którego realizowane są obozy naukowe. Jest przekonana, że każdego człowieka można zainteresować matematyką. Zaraża swoim entuzjazmem do tej dziedziny nauki. Uwielbia organizować wszelkiego rodzaju eventy, angażuje się we wszystko, co tylko może. Posiada niezliczone pokłady radości i uśmiechu, które wykorzystuje na każdym kroku swojego życia. Każdą wolną chwilę spędzałaby na górskich szlakach. Ciągle w podróży, zafascynowana pięknem świata i człowieka. Agata Baranowska jest także trenerką metody Brainy Abacus w Soward Smart Kids Academy w Lęborku. Brainy Abacus to prowadzony w kilku tysiącach szkół na całym świecie, oparty na naukowych podstawach międzynarodowy program treningu mózgu, matematyki mentalnej, pamięci i bardzo szybkiego liczenia. Metoda Brainy Abacus skutecznie poprawia koncentrację, skupienie i pamięć, poprawia umiejętność logicznego myślenia.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy spełnia swoje marzenie prowadząc zajęcia z matematyki.'
            ],
        },
        {
            name: 'Wojciech Malicki',
            id: 'Wojciech-Malicki',
            img1: WMimg1,
            img2: WMimg2,
            img3: WMimg3,
            text: [
                '- swój pierwszy program komputerowy napisał w wieku 12 lat na komputerze Commodore 64. Od tego czasu uczy się nowych języków programowania i nieustannie poszerza swoją wiedzę i umiejętności, przechodząc od Basica przez Pascala do C++ i Pythona po drodze zahaczając o JavaScript i VBA. Od 2010 roku pracuje jako nauczyciel, nie tylko w szkole, ale także w projektach związanych z zajęiami dla młodzieży uzdolnionej w Słupsku, Lęborku i Kartuzach. Od 2012 roku uczestniczy w obozach naukowych w czasie wakacji. Przygotowywał również uczniów do olimpiad informatycznych, w tym do olimpiady z programowania zespołowego. Aby podnieść swoje umiejętności uczestniczy w warsztatach informatycznych. Ukończył studia podyplomowe z zakresu testowania oprogramowania, programowania aplikacji internetowych oraz cyberbezpieczeństwa. Najbardziej ceni u siebie zapał do uczenia się nowych rzeczy i ciekawość. Obecnie studiuje filozofię.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z programowania.'
            ],
        },
        {
            name: 'Przemysław Rojewski',
            id: 'Przemyslaw-Rojewski',
            img1: PRimg1,
            img2: PRimg2,
            img3: PRimg3,
            text: [
                '- nauczyciel fizyki w Powiatowym Zespole Szkół nr 2 w Wejherowie. W zawodzie od ponad 20 lat. Przez 12 lat pracował z uczniami szczególnie uzdolnionymi w ramach Projektu „Zdolni z Pomorza” przygotowując ich do konkursów i olimpiad szczególnie w części doświadczalnej. Przez ten czas wychował wielu laureatów i finalistów. Co najważniejsze jest doświadczonym egzaminatorem i od wielu lat uczestniczy w pracach komisji egzaminacyjnej egzaminu maturalnego z fizyki. Również przygotowuje z powodzeniem uczniów do tego egzaminu. ',
                <br/>,
                <br/>,
                'Od kilku lat tworzy pracownię fizyczną z prawdziwego zdarzenia wyposażoną w najnowocześniejszy dostępny sprzęt pomiarowy. W czasie wolnym nie może obyć się bez treningów biegowych jest zwycięzcą wielu biegów długodystansowych i maratonów.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z fizyki.'
            ],
        },
        {
            name: 'Kamil Kostrzewa',
            id: 'Kamil-Kostrzewa',
            img1: KKimg1,
            img2: KKimg2,
            img3: KKimg3,
            text: [
                '- doktor nauk fizycznych, nauczyciel fizyki i matematyki z ponad dziesięcioletnim stażem, najpierw w gdańskich podstawówkach, gimnazjach i liceach, obecnie w lęborskim liceum. Aktywny popularyzator nauki, prowadzi zajęcia między innymi w MateManiakach, czy Zdolnych z Pomorza. Od ponad pięciu lat autor i twórca Wykładów Otwartych z Fizyki. Prywatnie miłośnik literatury i filmów fantasy, gier strategicznych oraz papierowych rpg-ów, siatkówki i eleganckich ubrań. Nauczycielem fizyki chciał być od zawsze (dokładniej od pierwszej lekcji fizyki w gimnazjum), choć z krótką przerwą na marzenia o byciu papieżem.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z matematyki.'
            ],
        },
        {
            name: 'Mateusz Woźniak',
            id: 'Mateusz-Woźniak',
            img1: MWimg1,
            img2: MWimg2,
            img3: MWimg3,
            text: [
                '- nauczyciel biologii i  chemii w X Liceum Ogólnokształcącym w Gdyni. Uczy i wychowuje od 20 roku życia.  Pracował na prawie wszystkich szczeblach edukacji – od szkoły podstawowej, po gimnazjum i szkoły ponadpodstawowe. Przez lata swojej pracy przygotowywał uczniów do konkursów, olimpiad w szczególności ekologicznej, biologicznej i chemicznej. Jest doświadczonym egzaminatorem z biologii i chemii i przez wiele lat aktywnie uczestniczył w pracach komisji egzaminacyjnej egzaminu maturalnego. Korepetytor pracujący przez lata w Indeks Edukacja i Ratajczuk Edukacja. W czasie zajęć przygotowywał uczniów do egzaminu maturalnego z biologii i z chemii. Od lat współpracuje z wydziałem Biologii  UG i jest opiekunem Samorządu XLO.',
                <br/>,
                <br/>,
                'Prywatnie uwielbia gry komputerowe (przygodówki, rpg i strategiczne),  jest także miłośnikiem gier planszowych. Nie pogardzi również kryminałem czy książką fantasy. ',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z chemii i biologii.',
            ],
        },
        {
            name: 'Ewa Tabor',
            id: 'Ewa-Tabor',
            img1: ETimg1,
            img2: ETimg2,
            img3: ETimg3,
            text: [
                '- studentka V roku psychologii na Wydziale Psychologii w Sopocie Uniwersytet SWPS - specjalność psychologia kliniczna, pracuje jako psycholog w Zespole Szkół w Garczegorzu. Po studiach planuje rozpocząć 4-letnie szkolenie specjalizacyjne w dziedzinie psychoterapia dzieci i młodzieży w podejściu poznawczo-behawioralnym. Praktyk w zakresie metod: „Kid’s Skills – Dam Radę oraz I’m Proud of You – Jestem z Ciebie Dumny”. ',
                <br/>,
                <br/>,
                'Trenerka metody Soward Skills w Soward Smart Kids Lębork. Na kursie uczy dzieci kluczowych kompetencji życiowych i społecznych. Program Soward Skills poprawia umiejętności dziecka w zakresie kreatywności, uważności, budowania relacji i panowania nad emocjami.',
                <br/>,
                <br/>,
                'Jest także trenerką ',
                <bold>Brainy Sensory Enhancement</bold>,
                ' - międzynarodowego programu rozwoju kluczowych kompetencji szkolnych i życiowych, odkrywania talentów i niewiarygodnych możliwości mózgu dziecka, pamięci i inteligencji wielorakich.',
                <br/>,
                <br/>,
                'Z dziećmi związana od 6 lat, od pracy w przedszkolach, po szkołę podstawową. W relacjach z dziećmi dostępna emocjonalnie i akceptująca bezwarunkowo. Wierzy w dzieci, ma serce i patrzy w serce. Posiada niespożyte pokłady energii, którymi dzieli się z innymi. Interesuje się tematem przemocy rówieśniczej i przemocy w rodzinie.',
                <br/>,
                <br/>,
                'Lubi czytać książki i oglądać filmy, ze szczególnym względnieniem tych z Tomem Hanksem w roli głównej. Piłka nożna, bieganie, jazda na hulajnodze i rowerze są jej bliskie. Wolontariuszka, niegdyś w domach seniora, przedszkolach, aktualnie w szeregach Szlachetnej Paczki.',
                <br/>,
                <br/>,
                'Miłośniczka zwierząt, wszystkich. Od kilku lat wegetarianka. Zauroczona naturą i każdą istotą żyjącą. Otwarta na piękno otaczającego świata, kreatywna, wytrwała, wdzięczna.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy prowadzi zajęcia z kompetencji społecznych.',
            ],
        },
        {
            name: 'Julia Ryńska',
            id: 'Julia-Rynska',
            img1: JRimg1,
            img2: JRimg2,
            img3: JRimg3,
            text: [
                ' - studentka psychologii na Uniwersytecie Warszawskim. Przewodnicząca sekcji graficznej w Kole Psychologii Dzieci i Młodzieży El niño. Poza zarządzaniem zespołem prowadzi warsztaty dla starszych i młodszych, ucząc ich rozpoznawania emocji i radzenia sobie ze stresem.',
                <br/>,
                <br/>,
                'Fanka psycholingwistyki i języków obcych, która wolne chwile spędza, podglądając życie zwierząt w naturze i w zoo. Od wielu lat praktykuje jogę oraz medytację. Jest również absolwentką kursu mindfulness.',
                <br/>,
                <br/>,
                'Uwielbia żyć Tu i Teraz, szukając małych radości każdego dnia. Przez wiele lat jeździła na Osadę Wiedzy jako uczestniczka, a teraz kontynuuje tę wspaniałą przygodę jako część kadry.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy pełni rolę wychowawcy otaczając wszystkich swoim niezwykłym wsparciem i pięknym sercem.',
            ],
        },
        {
            name: 'Mateusz Kąkol',
            id: 'Mateusz-Kąkol',
            img1: MKimg1,
            img2: MKimg2,
            img3: MKimg3,
            text: [
                '- student pierwszego roku Elektrotechniki na Politechnice Gdańskiej. Od 2016 roku pod opieką Ignacego Rejmaka, z którym rozpoczął swoją edukacyjną podróż w Gimnazjum nr 1 w Lęborku, rozwijał swoje zainteresowania do przedmiotów ścisłych. Jego pasja do fizyki i matematyki zaprowadziła go na obozy naukowe, w których brał udział od 2017 roku, aby w roku 2023 stać się jednym z członków kadry.',
                <br/>,
                <br/>,
                'Poza nauką, interesuje się elektroniką, motoryzacją oraz naprawą urządzeń. Aktywnie działa w społeczności studenckiej, oferując pomoc w nauce swoim kolegom poprzez udzielanie korepetycji.',
                <br/>,
                <br/>,
                'W Osadzie Wiedzy pełni rolę wychowawcy, spędzając z uczestnikami każdą wolną chwilę poza zajęciami, organizując czas wolny niezwykle aktywnie. Jako absolwent 7 obozów naukowych doskonale wie, jak ważny jest ruch po wzmożonym wysiłku umysłowym.',
            ],
        },
        {
            name: 'Cały zespół',
            img1: Team1,
            img2: Team2,
            img3: Team3,
        },
    ];
    return (
        <>
            {teamPersons.map((person) => {
                return (
                    <div id={person.id} key={person.name} className='team__section'>
                        <div className='team__name'>{person.name}</div>
                        <div className={person.text ? 'team__imgs' : 'team__imgs-no-text'}>
                            <div className={person.text ? 'team__img' : 'team__img-no-text'}>
                                <img src={person.img1} alt=''/>
                            </div>
                            <div className={person.text ? 'team__img' : 'team__img-no-text'}>
                                <img src={person.img2} alt=''/>
                            </div>
                            <div className={person.text ? 'team__img' : 'team__img-no-text'}>
                                <img src={person.img3} alt=''/>
                            </div>
                        </div>
                        <div className='team__text'>
                            <p>{person.text}</p>
                        </div>
                    </div>
                )
                    ;
            })}
        </>
    );
};

export default TeamPerson;
