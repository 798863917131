import TrainingMotivationImg1 from '../../assets/img/training/training_motivation_1.png'
import TrainingMotivationImg2 from '../../assets/img/training/training_motivation_2.png'

const TrainingMotivation = () => {
    return (
        <>
            <div className='training_motivation'>
                <section>
                    <div className='training_motivation-section-text'>
                        <h3>Nasze Motywacje</h3>
                        <p>
                            Naszą nadrzędną wartością w podejmowanych działaniach są relacje. Mamy kilkunastoletnie
                            doświadczenie w pracy z uczniami zdolnymi i mamy też świadomość, że ta grupa uczniów
                            potrzebuje
                            ogromnego, a przede wszystkim kompleksowego wsparcia.
                            <br/><br/>Lata naszej pracy pokazały nam w jaki sposób to robić i pragniemy dzielić się tym
                            z
                            innymi, wspierać w tym zakresie zarówno rodziców jak i nauczycieli, a młodym ludziom
                            pokazywać,
                            że warto sięgać wciąż wyżej.
                        </p>
                    </div>
                    <img src={TrainingMotivationImg1} className='image-big' alt="Natalia Zarańska z Ignaym Rejmakiem"/>
                </section>
                <section>
                    <img src={TrainingMotivationImg2} className='image-medium'
                         alt="Natalia Zarańska z Ignaym Rejmakiem"/>
                    <div className='training_motivation-section-text'>
                        <p>
                            Dziś rozumiemy, że „walka” o oceny dziecka w szkole może destrukcyjnie wpływać na jego
                            sukces i
                            wewnętrzną motywację, która jest wszak najważniejszym elementem osiąganych sukcesów.
                            <br/><br/>
                            W swojej wieloletniej pracy zawodowej mieliśmy kontakt z dziećmi mającymi wiele talentów
                            jednakże to determinacja, systematyczność, pewność siebie, świadomość własnej wartości,
                            odporność psychiczna, a przede wszystkim umiejętność radzenia sobie ze stresem i porażką
                            wpływała na ich późniejsze sukcesy.
                            <br/><br/>
                            Niezwykłą rolę odgrywali w tym miejscu także nauczyciele i rodzice, którzy doskonale
                            wiedzieli w
                            jaki sposób tworzyć środowisko sprzyjające tej drodze, bowiem praca z uczniem szczególnie
                            uzdolnionym nie jest łatwa jak się wielu nauczycielom wydaje. Często słyszymy słowa „zdolny
                            poradzi sobie sam”. W końcu bezbłędnie odtwarza On na sprawdzianach dokładnie to, co
                            nauczyciele
                            mówią na lekcji lub to, co jest w podręczniku, trafia w klucz odpowiedzi, więc dlaczego
                            poświęcać mu dodatkową uwagę.
                        </p>
                    </div>
                </section>
                <section>
                    <div className='training_motivation-section-text'>
                        <p>
                            Przecież sam sobie doskonale radzi...
                            <br/><br/>
                            <b>A może właśnie taki uczeń potrzebuje specjalnego traktowania?</b>
                            <br/><br/>
                            Może to właśnie praca z takim
                            uczniem wymaga innego podejścia, powinna stawiać przed nami zupełnie nowe wyzwania i zmuszać
                            nas
                            do myślenia nieszablonowego? Może to dla nas jest to dużo trudniejsze niż dla niego?
                            <br/><br/>
                            Efektywna praca z uczniami szczególnie uzdolnionymi wymaga dużej elastyczności. Musimy wyjść
                            poza schemat odtwarzany przez nas na lekcjach, poszukać nowych rozwiązań, które pozwolą nam
                            wydobyć z młodych ludzi ich potencjał.
                        </p>
                    </div>
                    <div className='training_motivation-quote'>
                        <p>W momencie gdy Twój głos na zajęciach jest wysłuchiwany, to czujesz przestrzeń do zadawania
                            pytań
                            i nie boisz się, że nie rozumiesz, bo okazuje się, że właśnie po to tu jesteś, żeby nie
                            rozumieć
                            i się dowiedzieć.
                            <br/><br/>
                            Szacunek jest super, autorytarna systemowa wyższość jest dołująca.*
                        </p>
                    </div>
                </section>
            </div>
            <div className="training_divider"></div>
        </>
    );
};

export default TrainingMotivation;