const SuccessEventReport = ({report}) => {
    return (
        <div className='success_report'>
            <div className='success_report-text'>
                <p className='date'>{report.date}</p>
                <h4>{report.title}</h4>
                <p className='description'>{report.description}</p>
            </div>
            <div className='success_report-photos'></div>
        </div>
    );
};

export default SuccessEventReport;