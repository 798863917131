import SuccessCreatorsImg from '../../assets/img/success/success_creators.png'
import ButtonWithArrow from "../common/ButtonWithArrow";

const SuccessCreators = () => {
    return (
        <div className='success_creators'>
            <h3 className='success_creators-heading-mobile'>Twórcy projektu</h3>
            <div className='success_creators_container'>
                <div className='success_creators-text'>
                    <h3 className='success_creators-heading-desktop'>Twórcy projektu</h3>
                    <p>Natalia Zarańska i Ignacy Rejmak, twórcy projektu „Sukcesu nie mierzy się oceną”, to pasjonaci
                        edukacji, którzy wspierają młodych w rozwijaniu talentów. Łączą bogate doświadczenie z
                        nowoczesnym
                        podejściem do nauczania, inspirując uczniów do odkrywania swoich możliwości.</p>
                    <ButtonWithArrow link='/zespol' backgroundColor='#6FA9B5' text='dowiedz się więcej'
                                     isInternalLink={true}/>
                </div>
                <img src={SuccessCreatorsImg} alt="Zdjęcie twórców projektu"/>
            </div>
        </div>
    );
};

export default SuccessCreators;