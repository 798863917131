import React, {useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './styles/app.css';
import './styles/success.css';
import './styles/training.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {useCookies} from 'react-cookie';

import Main from './pages/Main';
import Colony from './pages/Colony';
import History from './pages/History';
import Success from './pages/Success';
import Offer from './pages/Offer';
import Science from './pages/Science';
import SciencePhysics from './pages/science/SciencePhysics';
import ScienceMaths from './pages/science/ScienceMaths';
import ScienceProgramming from './pages/science/ScienceProgramming';
import ForMinors from './pages/ForMinors';
import Pasco from './pages/Pasco';
import Team from './pages/Team';
import Recommendations from './pages/Recomendations';
import Faq from './pages/Faq';
import Downloads from './pages/Downloads';
import Form from './pages/Form';
import Blog from './pages/Blog';
import Policy from './pages/Policy';
import Page404 from './pages/Page404';
import TeacherTraining from "./pages/TeacherTraining";
import ScrollRestoration from "./components/ScrollRestoration";

function App() {
    AOS.init({
        duration: 600,
        once: true,
    });

    //cookies
    const [cookies, setCookie] = useCookies(['CookiesOsadaWiedzy']);
    const handleCookie = () => {
        setCookie('CookiesOsadaWiedzy', 'CookiesAccepted', {path: '/'});
    };
    useEffect(() => {
        if (!cookies.CookiesOsadaWiedzy) {
            const cookiesBtn = document.querySelector('.cookies button');
            cookiesBtn.addEventListener('click', () => {
                cookiesBtn.parentElement.style.display = 'none';
            });
        }
    }, []);

    return (
        <BrowserRouter>
            <ScrollRestoration/>
            {!cookies.CookiesOsadaWiedzy && (
                <div className='cookies'>
                    <p>Ta strona wykorzystuje pliki cookies.</p>{' '}
                    <button onClick={handleCookie}>X</button>
                </div>
            )}
            <Switch>
                <Route exact path='/' component={Main}/>
                <Route exact path='/oboz' component={Colony}/>
                <Route exact path='/sukcesu-nie-mierzy-sie-ocena' component={Success}/>
                <Route exact path='/szkolenia-dla-nauczycieli' component={TeacherTraining}/>
                <Route exact path='/historia' component={History}/>
                <Route exact path='/oferta' component={Offer}/>
                <Route exact path='/nauki-scisle-po-godzinach' component={Science}/>
                <Route
                    exact
                    path='/nauki-scisle-po-godzinach/fizyka'
                    component={SciencePhysics}
                />
                <Route
                    exact
                    path='/nauki-scisle-po-godzinach/matematyka'
                    component={ScienceMaths}
                />
                <Route
                    exact
                    path='/nauki-scisle-po-godzinach/programowanie'
                    component={ScienceProgramming}
                />
                <Route exact path='/fizyka-dla-nieletnich' component={ForMinors}/>
                <Route exact path='/pasco' component={Pasco}/>
                <Route exact path='/zespol' component={Team}/>
                <Route exact path='/rekomendacje' component={Recommendations}/>
                <Route exact path='/do-pobrania' component={Downloads}/>
                <Route exact path='/zapisy' component={Form}/>
                <Route exact path='/faq' component={Faq}/>
                <Route exact path='/blog' component={Blog}/>
                <Route exact path='/polityka-prywatnosci' component={Policy}/>
                <Route component={Page404}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
