import React, { useEffect } from 'react';
import { toRoman } from '../../functions/functions';

const TrainingModule = ({ module, index }) => {
	useEffect(() => {
		let coll = document.querySelectorAll('.training_module-title');
		let cross = document.querySelectorAll('.expanded__cross');
		const handleExpand = (i) => {
			coll[i].parentElement.classList.toggle('active');
			let content = coll[i].parentElement.children[1];
			content.style.maxHeight
				? (content.style.maxHeight = null)
				: (content.style.maxHeight = content.scrollHeight + 'px');
		};
		for (let i = 0; i < coll.length; i++) {
			coll[i].addEventListener('click', () => handleExpand(i));
			cross[i].addEventListener('click', () => handleExpand(i));
		}
		return () => {
			for (let i = 0; i < coll.length; i++) {
				coll[i].removeEventListener('click', () => handleExpand(i));
				cross[i].removeEventListener('click', () => handleExpand(i));
			}
		};
	}, []);
	return (
		<div className='training_module training_module-expanded'>
			<h3 className='training_module-title'>Moduł {toRoman(index + 1)}</h3>
			<div className='expanded__text training_module-expanded__text'>
				<div className='training_module-text'>
					<h3>{module.title}</h3>
					<p dangerouslySetInnerHTML={{ __html: module.description }}></p>
				</div>
				<div className='training_module-quote'>
					<p dangerouslySetInnerHTML={{ __html: module.quote }}></p>
				</div>
			</div>
			<div className='expanded__cross'>
				<span className='cross__cross1'></span>
				<span className='cross__cross2'></span>
			</div>
		</div>
	);
};

export default TrainingModule;
