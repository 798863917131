import React from 'react';
import ScienceArrow from "../../assets/img/shortArrow.svg";
import {Link} from "react-router-dom";

const ButtonWithArrow = ({link, backgroundColor, text, isInternalLink}) => {
    const setSmoothScrollToDefault = () => {
        document.documentElement.style.scrollBehavior = "auto";
    }
    if (isInternalLink) {
        return (
            <Link to={link} style={{backgroundColor}} className='buttonWithArrow'
                  onClick={setSmoothScrollToDefault}> <span>{text}</span>
                <img src={ScienceArrow} alt='Strzałka zobacz więcej'/>
            </Link>
        )
    } else {
        return (
            <a href={link} style={{backgroundColor}} className='buttonWithArrow'
               onClick={setSmoothScrollToDefault}>
                <span>{text}</span>
                <img src={ScienceArrow} alt='Strzałka zobacz więcej'/>
            </a>
        )
    }
};

export default ButtonWithArrow;