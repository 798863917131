const TrainingIntro = () => {
    return (
        <div className='training_intro'>
            <section>
                <div className='training_intro-text'>
                    <span>Serdecznie zapraszamy nauczycieli na wyjątkowy cykl szkoleń:</span>
                    <h3>Edukacja bez granic: Rozwój talentów i kompetencji w nowoczesnej szkole</h3>
                </div>
                <div className='training_intro-quote'>
                    <p>
                        Najłatwiejszą rzeczą na świecie jest być sobą. Najtrudniejszą natomiast jest być kimś, kto
                        spełnia oczekiwania innych.
                        <span>Leo Buscaglia</span>
                    </p>
                </div>
            </section>
            <div className='training_divider'></div>
            <section>
                <div className='training_intro-text'>
                    <div>
                        <h3>Dla kogo:</h3>
                        <p>Szkolenie skierowane do nauczycieli i realizowane stacjonarnie podczas Rady
                            Pedagogicznej.</p>
                    </div>
                    <div>
                        <h3>Czas trwania:</h3>
                        <p>Każdy moduł realizowany jest podczas 3 godzin warsztatowych.</p>
                    </div>
                    <div>
                        <h3>Struktura szkolenia:</h3>
                        <p>Szkolenie podzielone zostało na 3 moduły, a każdy kolejny moduł jest kontynuacją
                            poprzedniego. </p>
                    </div>
                </div>
                <div className='training_intro-quote'>
                    <p>
                        Powinno się tworzyć przestrzeń żeby właśnie nie krępować wyobraźni i umiejętności ludzi
                        uzdolnionych. Dać narzędzia, dać miejsce, niech zrobią z tym co chcą, bez sprawdzianów,
                        kartkówek, utartych schematów.*
                    </p>
                    <p>Najbardziej odczułam, nieznajomość siebie, presję, osamotnienie, niskie poczucie własnej
                        wartości, bo liczy się tylko to, co zrobisz, a wszystkim zawsze jest mało.*</p>
                </div>
            </section>
        </div>
    );
};

export default TrainingIntro;