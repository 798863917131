import SuccessIntroImg from '../../assets/img/success/success_intro.png'

const SuccessIntro = () => {
    return (
        <div className='success_intro'>
            <div className='success_intro-text'>
                <h3>Sukcesu nie mierzy się oceną</h3>
                <p>Projekt „Sukcesu nie mierzy się oceną” zrodził się w umysłach ludzi, dla których obszar
                    współczesnej edukacji w zakresie wsparcia uczniów uzdolnionych w kierunku przedmiotów
                    ścisłych, stanowi szczególną wartość. </p>
            </div>
            <img src={SuccessIntroImg} alt="Mapa myśli"/>
        </div>
    );
};

export default SuccessIntro;