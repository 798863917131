import React from 'react'


import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import RecommendationsContent from '../components/RecomendationsContent'

const Recommendations = () => {
    return (
        <>
            <Navbar />
            <RecommendationsContent />
            <Footer />
        </>
    )
}

export default Recommendations;