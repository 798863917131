import Tree from "../assets/img/main/Tree.svg";
import Cpu from "../assets/img/main/Cpu.svg";
import LightbulbFilament from "../assets/img/main/LightbulbFilament.svg";
import Certificate from "../assets/img/main/Certificate.svg";
import FilmStrip from "../assets/img/main/FilmStrip.svg";
import Magnet from "../assets/img/main/Magnet.svg";
import ButtonWithArrow from "./common/ButtonWithArrow";
import ForestBackground from '../assets/img/main/forestBackground.png';
import GradientBackground from '../assets/img/main/gradientBackground.png';
import ArrowDown from '../assets/img/main/arrowDown.png';
import Gif from '../assets/img/physicsLecturerGif1.gif';

const MainContent = () => {
    const Tiles = [
        {
            title: 'Obóz naukowy',
            description: 'Wyjątkowy obóz naukowy, przestrzeń budowania relacji, rozwijania pasji i zainteresowań.',
            link: '/oboz',
            icon: Tree,
            color: '#5C9443'
        },
        {
            title: 'Zajęcia pozalekcyjne',
            description: 'Nauki ścisłe po godzinach – zajęcia pozalekcyjne  z fizyki, matematyki i programowania dedykowane uczniom, maturzystom  oraz studentom.',
            link: '/nauki-scisle-po-godzinach',
            icon: Cpu,
            color: '#4B93A2'
        },
        {
            title: 'Projekt: Sukcesu nie mierzy się oceną',
            description: 'Innowacyjny projekt edukacyjny, który  rozwija kluczowe kompetencje przyszłości u uczniów.',
            link: '/sukcesu-nie-mierzy-sie-ocena',
            icon: LightbulbFilament,
            color: '#4B93A2'
        }
        ,
        {
            title: 'Szkolenia dla nauczycieli',
            description: 'Cykl szkoleń dla nauczycieli: Edukacja bez granic – Rozwój talentów i kompetencji w nowoczesnej szkole.',
            link: '/szkolenia-dla-nauczycieli',
            icon: Certificate,
            color: '#B17DE5'
        }
        ,
        {
            title: 'Underage.pro',
            description: 'System unikalnych działań medialnych o charakterze popularnonaukowym.',
            link: '/fizyka-dla-nieletnich',
            icon: FilmStrip,
            color: '#B17DE5'
        }
        ,
        {
            title: 'Pasco',
            description: 'Urządzenia cyfrowe do pomiarów i analiz podczas doświadczeń na lekcjach przedmiotów przyrodniczych.',
            link: '/pasco',
            icon: Magnet,
            color: '#B17DE5'
        }]

    return (
        <div className="main_container">
            <div className='main_container-overlay'>
                <img src={ForestBackground} alt="Las"/>
                <img src={GradientBackground} alt="Gradient"/>
            </div>
            <main className="main">
                <div className='main-text'>
                    <h1>Osada Wiedzy</h1>
                    <h4><span>przestrzeń edukacyjnych inicjatyw</span> dla dzieci, młodzieży i nauczycieli <br className='br'/>
                        związanych z przedmiotami ścisłymi</h4>
                    <div className='arrowDown'>
                        <a href="#oboz">
                            <img src={ArrowDown} alt="Strzałka wskazująca w dół"/>
                        </a>
                    </div>
                    <img src={Gif} className='main-text-gif' alt="Gif"/>
                </div>
                <section>
                    {Tiles.map((tile) => {
                        return (
                            <div className='main_tile' id='oboz'>
                                <div style={{backgroundColor: tile.color}} className='main_tile-img_container'>
                                    <div>
                                        <img
                                            src={tile.icon}
                                            alt="Tematyczna ikona"
                                        />
                                    </div>
                                </div>
                                <div className='main_tile-content'>
                                    <div className='main_tile-text'>
                                        <h2>{tile.title}</h2>
                                        <p>{tile.description}</p>
                                    </div>
                                    <ButtonWithArrow link={tile.link} backgroundColor='#9AC181'
                                                     text='dowiedz się więcej'
                                                     isInternalLink={true}/>
                                </div>
                            </div>
                        )
                    })}
                </section>
            </main>
        </div>
    );
};

export default MainContent;
