const TrainingPricing = () => {
    return (
        <>
            <div className='training_pricing'>
                <section>
                    <div className='training_pricing-text'>
                        <p>Koszt każdego Modułu – 3500,00 zł</p>
                        <p>
                            <bold>UWAGA!</bold>
                            {' '}
                            Przy zakupie wszystkich modułów jednocześnie, istnieje możliwość otrzymania
                            zniżek.
                        </p>
                        <p>
                            <bold>UWAGA!</bold>
                            {' '}
                            W przypadku szkolenia odbywającego się poza terenem województwa pomorskiego do
                            całkowitego
                            kosztu szkolenia należy doliczyć koszt przejazdu i ewentualnego noclegu.
                        </p>
                    </div>
                    <div className='training_pricing-quote'>
                        <p>Uczeń zdolny potrzebuje wsparcia, by zrozumieć, że nie ze wszystkiego musi być najlepszy, że
                            może
                            mieć gorszy czas i może mieć potknięcia, że może czegoś nie zrozumieć i mieć obawy.*</p>
                    </div>
                </section>
            </div>
            <div className="training_divider"></div>
            <p className='training_additional-info'>* – cytaty absolwentów projektu „Wyobraźnia ważniejsza od
                wiedzy” realizowanego w latach 2010 – 2018 w
                Gimnazjum nr 1 w Lęborku.</p>
        </>
    );
};

export default TrainingPricing;